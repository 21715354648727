<template>
  <div class="d-flex justify-content-center">
    <div class="w-50">
      <div
        v-if="form.error"
        class="alert alert-danger"
      >
        {{ form.error }}
      </div>

      <form @submit.prevent>
        <label for="email">E-mail</label>
        <input
          v-model="form.data.email"
          name="email"
          type="email"
          class="form-control"
          placeholder="Enter E-mail"
          required
        >

        <label
          class="mt-3"
          for="password"
        >Password</label>
        <input
          v-model="form.data.password"
          name="password"
          type="password"
          class="form-control"
          placeholder="Enter Password"
          required
        >

        <button
          type="submit"
          class="btn btn-primary mt-3"
          :disabled="form.pending"
          @click.prevent="loginSubmit"
        >
          Login
        </button>
      </form>
    </div>
  </div>
</template>

<script setup lang="ts">
definePageMeta({
  middleware: "guest-only",
  layout: "container",
});

const { login } = useAuth();

const form = reactive({
  data: {
    email: "",
    password: "",
    rememberMe: true,
  },
  error: "",
  pending: false,
});

async function loginSubmit() {
  try {
    form.error = "";
    // TODO: Use loading indicator instead pending
    form.pending = true;

    await login(form.data.email, form.data.password, form.data.rememberMe);

    await navigateTo({ name: "index" });
  } catch (error) {
    console.error(error);
    if (error.data.message) form.error = error.data.message;
  } finally {
    form.pending = false;
  }
}
</script>
